import Vue from 'vue'
import axios from 'axios'

axios.defaults.baseURL = 'https://plataforma.menuvem.com.br/application/group-e6e7b09b508ddbe7c392494263990b8d/client-f987599dbbe020da58bbadb60faef45d/'
//https://s3-sa-east-1.amazonaws.com/nuc-storage.menuvem.com.br/application/group-1471ed47b6159f4f7ab53bddbcb75f67/img/logo-a.png

Vue.use({
    install(Vue){
        Vue.prototype.$http = axios
    }
})