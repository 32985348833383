<template>
<v-app>
    <v-main :style="'background-color:' + group.background.background">
      <div>
        <v-container style="font-family: 'Ubuntu'!important;">
          <v-row class="text-center mt-7">
            <v-col cols="12">
              <v-img
                :src="group.logo"
                class="my-3"
                contain
                height="150"
              />
            </v-col>
            <div :style="'width: 100%; color:'+group.text.color" class="mx-4">
              {{group.text.text}}
            </div>
          </v-row>
          <div style="display: flex; justify-content: center; flex-wrap: wrap;">
            <StoreItem v-for="(store) in stores" :key="store.id" :store="store" :colorButton="group.background.background" :whatsApp="whatsApp"/>
          </div>

        </v-container>

      </div>
    </v-main>
</v-app>
</template>

<script>
  import StoreItem from '@/components/StoreItem';
  export default {
    name: 'StoreList',
    data(){
      return{
        storesStates: [],
        stores: [],
        group:{background:{background:'#fffff'},text:{color:'#000000'}},
        apps: [
          {
            name:'App Android',
            link:'https://play.google.com/store/apps/details?id=br.com.nuctecnologia.redhillburger',
            image: require('../assets/play-store.png')
          },
          {
            name:'App Store',
            link:'https://apps.apple.com/app/red-hill-burger/id1641724392',
            image: require('../assets/app-store.png')
          }
        ],
        whatsApp:[
            ['66 9 9912-6040','https://api.whatsapp.com/send?1=pt_BR&phone=5566999126040','f987599dbbe020da58bbadb60faef45d'],
            ['66 9 9662-1103','https://api.whatsapp.com/send?1=pt_BR&phone=5566996621103','d2b04943d0c66c8650988c876241c0f6']
        ],
      }
    },
    components: {
      StoreItem,
    },
    created() {
        this.$http.get('stores').then((response) => {
          this.group = response.data.group
          this.storesStates = response.data.stores
          for (const state in this.storesStates) {
            for (const city in this.storesStates[state]){
              for (const store in this.storesStates[state][city]){
                this.stores.push(this.storesStates[state][city][store])
              }
            }
          }
        });
    },
  }
</script>
